<template>
  <div class="admin-feed-block AdminSubscriptionsReport">
    <AdminStatisticsFilter :statistics="statistics" :title="$locale['subscriptions']">
      <LargeButton
        :icon="`users`"
        :label="$locale['total_subscriptions']"
        :rightDesc="`<strong skeleton>${number_format(statistics.totalItems)}</strong>`"
        @click="setQuery({ admin: 'statistics-flowers-details', startDate, endDate, period: 'custom' })"
        prevent
      />
      <LargeButton
        :icon="`filter-list`"
        :label="$locale['filters']"
        :rightIcon="false"
        @click="
          view({
            title: $locale['filters'],
            component: () => import('./AdminSubscriptionsReportFilters'),
            closeOutside: true,
            class: 'menu bottom-mbl',
            animation: 'bottomIn',
          })
        "
      />
    </AdminStatisticsFilter>

    <div :class="`statistics-block __general ${$loading ? '__loading' : '__loaded'}`">
      <div class="ReportInfo">
        <LargeButton
          v-for="(subscription, index) in items"
          :key="index"
          :label="subscription.user.name"
          :desc="`@${subscription.creator.user} ${date_locale(subscription.date)}`"
          :UserAvatar="subscription.user"
          :iconSize="`48px`"
          :rightDesc="`<strong skeleton nowrap>$ ${number_format(subscription.price, 2)}</strong><small skeleton>${$locale['usd']}</small>`"
          prevent
        />
        <LoadMore :morepage="statistics.hasMore" @more="getStatistics({ page: statistics.page + 1 })" />
      </div>
    </div>
  </div>
</template>

<script>
import AdminStatistics from "./AdminStatistics";
export default {
  mixins: [AdminStatistics],
  components: {
    AdminStatisticsFilter: () => import("./AdminStatisticsFilter.vue"),
    AdminStatisticsUsersCreatorsCard: () => import("./AdminStatisticsUsersCreatorsCard.vue"),
  },
  data: function() {
    return {
      store: Go.store("admin_statistics"),
      items: [],
      statistics: { totalItems: 0, page: 1 },
      axios: {
        headers: {
          "use-form": "false",
        },
      },
    };
  },
  methods: {
    getStatistics: async function({ page = 1 } = {}) {
      this.isLoading(true);
      await Go.sleep(250);

      try {
        this.query = Go.objectToQuery(this.$route.query);
        this.req = await this.$api.get(`${this.$apiHost}/admin/admin-subscriptions?${this.query}&page=${page}`);
        this.reqSuccess(this.req.data);
      } catch (error) {
        this.reqError(error);
      }

      this.isLoading(false);
    },
    reqSuccess: function({ statistics, items }) {
      if (statistics && Number(statistics.page) === 1) {
        this.store.set("statistics", statistics);
        this.store.set("items", items);
        this.statistics = statistics;
        this.items = items;
        return;
      }

      this.statistics = statistics;
      this.items = [...this.items, ...items];
      this.store.set("statistics", statistics);
      this.store.set("items", this.items);
    },
    reqError: function(data = {}) {
      this.showMessage(data);
    },
  },
  mounted: function() {
    this.items = this.store.get("items") || [];
    this.statistics = this.store.get("statistics") || { totalItems: 0 };
    this.getStatistics({ page: 1 });
  },
};
</script>
